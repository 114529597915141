<template>
  <section>
    <folha v-for="(elementos, indexFolha) in listaDeFetos" :key="indexFolha">
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />
      <br v-if="indexFolha != 0" />

      <div v-if="indexFolha === 0">
        <br />
        <br />
        <br />
        <br />
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <br />
        </div>

        <br />
        <br />
        <h3 v-for="(titulo, indice) in title" :key="indice">{{ titulo }}</h3>

        <br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <p></p>
          <!-- <p></p>
          <p></p> -->
          {{ elemento }}
          <p></p>
        </h4>

        <div v-else-if="elemento === 'idadeGest0'">
          Idade gestacional baseada na biometria fetal atual de
          <strong
            >{{ laudo[0].semanasDUM2 ? laudo[0].semanasDUM2 : '' }}
            {{
              laudo[0].semanasDUM2
                ? laudo[0].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }}{{ laudo[0].semanasDUM2 && laudo[0].diasDUM2 ? ' e ' : ''
            }}{{ laudo[0].diasDUM2 ? laudo[0].diasDUM2 : ''
            }}{{
              laudo[0].diasDUM2
                ? laudo[0].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }}</strong
          >{{ laudo[0].examServico ? ', baseada na ultrassonografia de primeiro trimestre ('
          + laudo[0].examServico + ')'
          
          :''}}{{
            laudo[0].compativelIncompativel
              ? ', ' + laudo[0].compativelIncompativel
              : ''
          }}{{
            laudo[0].compativelIncompativel
              ? ' com a biometria atual'
              : ''
          }}.
        </div>
        <div v-else-if="elemento === 'idadeGest1'">
          Idade gestacional baseada na biometria fetal atual de
          <strong
            >{{ laudo[1].semanasDUM2 ? laudo[1].semanasDUM2 : '' }}
            {{
              laudo[1].semanasDUM2
                ? laudo[1].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }}{{ laudo[1].semanasDUM2 && laudo[1].diasDUM2 ? ' e ' : ''
            }}{{ laudo[1].diasDUM2 ? laudo[1].diasDUM2 : ''
            }}{{
              laudo[1].diasDUM2
                ? laudo[1].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }} </strong
          >{{ laudo[1].examServico ? ', baseada na ultrassonografia de primeiro trimestre '
          + laudo[1].examServico
          
          :''}}{{
            laudo[1].compativelIncompativel
              ? ', ' + laudo[1].compativelIncompativel
              : ''
          }}{{
            laudo[1].compativelIncompativel
              ? ' com a biometria atual'
              : ''
          }}.
        </div>
        <div v-else-if="elemento === 'idadeGest2'">
          Idade gestacional baseada na biometria fetal atual de
          <strong
            >{{ laudo[2].semanasDUM2 ? laudo[2].semanasDUM2 : '' }}
            {{
              laudo[2].semanasDUM2
                ? laudo[2].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }}{{ laudo[2].semanasDUM2 && laudo[2].diasDUM2 ? ' e ' : ''
            }}{{ laudo[2].diasDUM2 ? laudo[2].diasDUM2 : ''
            }}{{
              laudo[2].diasDUM2
                ? laudo[2].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }} </strong
          >{{ laudo[2].examServico ? ', baseada na ultrassonografia de primeiro trimestre '
          + laudo[2].examServico
          
          :''}}{{
            laudo[2].compativelIncompativel
              ? ', ' + laudo[2].compativelIncompativel
              : ''
          }}{{
            laudo[2].compativelIncompativel
              ? ' com a biometria atual'
              : ''
          }}.
        </div>
        <div v-else-if="elemento === 'idadeGest3'">
          Idade gestacional baseada na biometria fetal atual de
          <strong
            >{{ laudo[3].semanasDUM2 ? laudo[3].semanasDUM2 : '' }}
            {{
              laudo[3].semanasDUM2
                ? laudo[3].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }}{{ laudo[3].semanasDUM2 && laudo[3].diasDUM2 ? ' e ' : ''
            }}{{ laudo[3].diasDUM2 ? laudo[3].diasDUM2 : ''
            }}{{
              laudo[3].diasDUM2
                ? laudo[3].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }} </strong
          >{{ laudo[3].examServico ? ', baseada na ultrassonografia de primeiro trimestre '
          + laudo[3].examServico
          
          :''}}{{
            laudo[3].compativelIncompativel
              ? ', ' + laudo[3].compativelIncompativel
              : ''
          }}{{
            laudo[3].compativelIncompativel
              ? ' com a biometria atual'
              : ''
          }}.
        </div>
        <div v-else-if="elemento === 'idadeGest4'">
          Idade gestacional baseada na biometria fetal atual de
          <strong
            >{{ laudo[4].semanasDUM2 ? laudo[4].semanasDUM2 : '' }}
            {{
              laudo[4].semanasDUM2
                ? laudo[4].semanasDUM2 > 1
                  ? ' semanas'
                  : ' semana '
                : ''
            }}{{ laudo[4].semanasDUM2 && laudo[4].diasDUM2 ? ' e ' : ''
            }}{{ laudo[4].diasDUM2 ? laudo[4].diasDUM2 : ''
            }}{{
              laudo[4].diasDUM2
                ? laudo[4].diasDUM2 > 1
                  ? ' dias'
                  : ' dia'
                : ''
            }} </strong
          >{{
            laudo[4].compativelIncompativel
              ? ', ' + laudo[4].compativelIncompativel
              : ''
          }}{{
            laudo[4].compativelIncompativel
              ? ' com a idade gestacional baseada na DUM'
              : ''
          }}.
        </div>

        <div v-else-if="elemento === 'coloPassouAMedir0'">
          Após três minutos de avaliação, o colo
          {{ laudo[0].coloPassouAMedir }} <strong>{{ laudo[0].cm }}</strong> mm.
        </div>
        <div v-else-if="elemento === 'coloPassouAMedir1'">
          Após três minutos de avaliação, o colo
          {{ laudo[1].coloPassouAMedir }} <strong>{{ laudo[1].cm }}</strong> mm.
        </div>
        <div v-else-if="elemento === 'coloPassouAMedir2'">
          Após três minutos de avaliação, o colo
          {{ laudo[2].coloPassouAMedir }} <strong>{{ laudo[2].cm }}</strong> mm.
        </div>
        <div v-else-if="elemento === 'coloPassouAMedir3'">
          Após três minutos de avaliação, o colo
          {{ laudo[3].coloPassouAMedir }} <strong>{{ laudo[3].cm }}</strong> mm.
        </div>
        <div v-else-if="elemento === 'coloPassouAMedir4'">
          Após três minutos de avaliação, o colo
          {{ laudo[4].coloPassouAMedir }} <strong>{{ laudo[4].cm }}</strong> mm.
        </div>
        <div v-else-if="elemento === 'dataProv0'">
          Data provável do parto:
          <strong>{{ dataFormatada(laudo[0].dataProvavelParto) }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'dataProv1'">
          Data provável do parto:
          <strong>{{ dataFormatada(laudo[1].dataProvavelParto) }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'dataProv2'">
          Data provável do parto:
          <strong>{{ dataFormatada(laudo[2].dataProvavelParto) }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'dataProv3'">
          Data provável do parto:
          <strong>{{ dataFormatada(laudo[3].dataProvavelParto) }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'dataProv4'">
          Data provável do parto:
          <strong>{{ dataFormatada(laudo[4].dataProvavelParto) }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaDois0'">
          Trissomia do 21 - 1: <strong>{{ laudo[0].riscoTrissomiadois }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaDois1'">
          Trissomia do 21 - 1: <strong>{{ laudo[1].riscoTrissomiadois }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaDois2'">
          Trissomia do 21 - 1: <strong>{{ laudo[2].riscoTrissomiadois }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaDois3'">
          Trissomia do 21 - 1: <strong>{{ laudo[3].riscoTrissomiadois }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaDois4'">
          Trissomia do 21 - 1: <strong>{{ laudo[4].riscoTrissomiadois }}</strong
          >.
        </div>

        <div v-else-if="elemento === 'TrissomiaUm0'">
          Trissomia do 21 - 1: <strong>{{ laudo[0].riscoTrissomiaum }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaUm1'">
          Trissomia do 21 - 1: <strong>{{ laudo[1].riscoTrissomiaum }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaUm2'">
          Trissomia do 21 - 1: <strong>{{ laudo[2].riscoTrissomiaum }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaUm3'">
          Trissomia do 21 - 1: <strong>{{ laudo[3].riscoTrissomiaum }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'TrissomiaUm4'">
          Trissomia do 21 - 1: <strong>{{ laudo[4].riscoTrissomiaum }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'fraseRisco1'">
          A gestante foi classificada como <strong>baixo risco</strong> para
          desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas
          ≥ 1:100).
        </div>
        <div v-else-if="elemento === 'fraseRisco2'">
          A gestante foi classificada como <strong>alto risco</strong> para
          desenvolver pré-eclâmpsia tardia (risco de pré-eclâmpsia antes de 37
          semanas ≥ 1:100).
        </div>
        <div v-else-if="elemento === 'fraseRisco3'">
          A gestante foi classificada como <strong>risco aumentado</strong> para
          desenvolver pré-eclâmpsia precoce (risco de pré-eclâmpsia antes de 34
          semanas ≥ 1:100).
        </div>

        <div v-else-if="elemento === 'polipoMede0'">
          {{ laudo[0].polipoEndocervicalMedindo }}
          <strong>{{ laudo[0].polipoEndocervicalMedindoCm }} </strong> mm.
        </div>
        <div v-else-if="elemento === 'polipoMede1'">
          {{ laudo[0].polipoEndocervicalMedindo }}
          <strong>{{ laudo[1].polipoEndocervicalMedindoCm }} </strong> mm.
        </div>
        <div v-else-if="elemento === 'polipoMede2'">
          {{ laudo[0].polipoEndocervicalMedindo }}
          <strong>{{ laudo[2].polipoEndocervicalMedindoCm }} </strong> mm.
        </div>
        <div v-else-if="elemento === 'polipoMede3'">
          {{ laudo[0].polipoEndocervicalMedindo }}
          <strong>{{ laudo[3].polipoEndocervicalMedindoCm }} </strong> mm.
        </div>
        <div v-else-if="elemento === 'polipoMede4'">
          {{ laudo[0].polipoEndocervicalMedindo }}
          <strong>{{ laudo[4].polipoEndocervicalMedindoCm }} </strong> mm.
        </div>

        <div v-else-if="elemento === 'riscoPrec0'">
          Risco para pré-eclâmpsia precoce - 1:
          <strong>{{ laudo[0].riscoBasalPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoPrec1'">
          Risco para pré-eclâmpsia precoce - 1:
          <strong>{{ laudo[1].riscoBasalPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoPrec2'">
          Risco para pré-eclâmpsia precoce - 1:
          <strong>{{ laudo[2].riscoBasalPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoPrec3'">
          Risco para pré-eclâmpsia precoce - 1:
          <strong>{{ laudo[3].riscoBasalPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoPrec4'">
          Risco para pré-eclâmpsia precoce - 1:
          <strong>{{ laudo[4].riscoBasalPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoTrissomia0'">
          O risco de trissomia do 13, 18 e 21
          <strong>{{ laudo[0].riscoTrissomia }}</strong> após a medida da TN no
          feto avaliado, sendo considerado de
          <strong>{{ laudo[0].taxaRisco }}</strong> para cromossomopatias.
        </div>
        <div v-else-if="elemento === 'riscoTrissomia1'">
          O risco de trissomia do 13, 18 e 21
          <strong>{{ laudo[1].riscoTrissomia }}</strong> após a medida da TN no
          feto avaliado, sendo considerado de
          <strong>{{ laudo[1].taxaRisco }}</strong> para cromossomopatias.
        </div>
        <div v-else-if="elemento === 'riscoTrissomia2'">
          O risco de trissomia do 13, 18 e 21
          <strong>{{ laudo[2].riscoTrissomia }}</strong> após a medida da TN no
          feto avaliado, sendo considerado de
          <strong>{{ laudo[2].taxaRisco }}</strong> para cromossomopatias.
        </div>
        <div v-else-if="elemento === 'riscoTrissomia3'">
          O risco de trissomia do 13, 18 e 21
          <strong>{{ laudo[3].riscoTrissomia }}</strong> após a medida da TN no
          feto avaliado, sendo considerado de
          <strong>{{ laudo[3].taxaRisco }}</strong> para cromossomopatias.
        </div>
        <div v-else-if="elemento === 'riscoTrissomia4'">
          O risco de trissomia do 13, 18 e 21
          <strong>{{ laudo[4].riscoTrissomia }}</strong> após a medida da TN no
          feto avaliado, sendo considerado de
          <strong>{{ laudo[1].taxaRisco }}</strong> para cromossomopatias.
        </div>

        <div v-else-if="elemento === 'coloUter0'">
          {{ laudo[0].coloUterino ? 'Colo uterino ' : ''
          }}{{ laudo[0].coloUterino ? laudo[0].coloUterino : ''
          }}{{ laudo[0].coloUterino ? ', ' : ''
          }}{{ laudo[0].coloMedidaEmRepouco ? 'com menor comprimento de ' : ''
          }}<strong>{{
            laudo[0].coloMedidaEmRepouco ? laudo[0].coloMedidaEmRepouco : ''
          }}</strong
          >{{ laudo[0].coloMedidaEmRepouco ? ' mm (colo avaliado por três minutos)' : '' }}.
        </div>
        <div v-else-if="elemento === 'coloUter1'">
          {{ laudo[1].coloUterino ? 'Colo uterino ' : ''
          }}{{ laudo[1].coloUterino ? laudo[1].coloUterino : ''
          }}{{ laudo[1].coloUterino ? ', ' : ''
          }}{{ laudo[1].coloMedidaEmRepouco ? 'com menor comprimento de ' : ''
          }}<strong>{{
            laudo[1].coloMedidaEmRepouco ? laudo[1].coloMedidaEmRepouco : ''
          }}</strong
          >{{ laudo[1].coloMedidaEmRepouco ? ' mm (colo avaliado por três minutos)' : '' }}.
        </div>
        <div v-else-if="elemento === 'coloUter2'">
          {{ laudo[2].coloUterino ? 'Colo uterino ' : ''
          }}{{ laudo[2].coloUterino ? laudo[2].coloUterino : ''
          }}{{ laudo[2].coloUterino ? ', ' : ''
          }}{{ laudo[2].coloMedidaEmRepouco ? 'com menor comprimento de ' : ''
          }}<strong>{{
            laudo[2].coloMedidaEmRepouco ? laudo[2].coloMedidaEmRepouco : ''
          }}</strong
          >{{ laudo[2].coloMedidaEmRepouco ? ' mm (colo avaliado por três minutos)' : '' }}.
        </div>
        <div v-else-if="elemento === 'coloUter3'">
          {{ laudo[3].coloUterino ? 'Colo uterino ' : ''
          }}{{ laudo[3].coloUterino ? laudo[3].coloUterino : ''
          }}{{ laudo[3].coloUterino ? ', ' : ''
          }}{{ laudo[3].coloMedidaEmRepouco ? 'com menor comprimento de ' : ''
          }}<strong>{{
            laudo[3].coloMedidaEmRepouco ? laudo[3].coloMedidaEmRepouco : ''
          }}</strong
          >{{ laudo[3].coloMedidaEmRepouco ? ' mm (colo avaliado por três minutos)' : '' }}.
        </div>
        <div v-else-if="elemento === 'coloUter4'">
          {{ laudo[4].coloUterino ? 'Colo uterino ' : ''
          }}{{ laudo[4].coloUterino ? laudo[4].coloUterino : ''
          }}{{ laudo[4].coloUterino ? ', ' : ''
          }}{{ laudo[4].coloMedidaEmRepouco ? 'com menor comprimento de ' : ''
          }}<strong>{{
            laudo[4].coloMedidaEmRepouco ? laudo[4].coloMedidaEmRepouco : ''
          }}</strong
          >{{ laudo[4].coloMedidaEmRepouco ? ' mm (colo avaliado por três minutos)' : '' }}.
        </div>
        <div v-else-if="elemento === 'riscoCorrigidoPreEclampsia0'">
          Risco para pré-eclâmpsia tardia - 1:
          <strong> {{ laudo[0].riscoCorrigidoPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoCorrigidoPreEclampsia1'">
          Risco para pré-eclâmpsia tardia - 1:
          <strong> {{ laudo[1].riscoCorrigidoPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoCorrigidoPreEclampsia2'">
          Risco para pré-eclâmpsia tardia - 1:
          <strong> {{ laudo[2].riscoCorrigidoPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoCorrigidoPreEclampsia3'">
          Risco para pré-eclâmpsia tardia - 1:
          <strong> {{ laudo[3].riscoCorrigidoPreEclampsia }}</strong
          >.
        </div>
        <div v-else-if="elemento === 'riscoCorrigidoPreEclampsia4'">
          Risco para pré-eclâmpsia tardia - 1:
          <strong> {{ laudo[4].riscoCorrigidoPreEclampsia }}</strong
          >.
        </div>
        <h3 v-else-if="elemento === 'feto0'">
          <strong> Feto 1</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto1'">
          <strong> Feto 2</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto2'">
          <strong> Feto 3</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto3'">
          <strong> Feto 4</strong>
        </h3>
        <h3 v-else-if="elemento === 'feto4'">
          <strong> Feto 5</strong>
        </h3>

        <div v-else-if="elemento === 'gest'">
          <strong>
            {{ laudo.length == 1 ? 'Gestação única ' : '' }}
            {{ laudo.length == 2 ? 'Gestação Gemelar ' : '' }}
            {{ laudo.length == 3 ? 'Gestação Trigemelar ' : '' }}
            {{ laudo.length == 4 ? 'Gestação Quadrigemelar ' : '' }}
            {{ laudo.length == 5 ? 'Gestação quíntupla com ' : '' }}</strong
          >
          </div>

        <p v-else-if="elemento === 'espaço'"></p>

        <p v-else-if="elemento === 'fraseBug1'" style="text-align: left">
          * Risco corrigido alto - indicação de investigação adicional (biópsia
          de vilo corial ou amniocentese).
        </p>

        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == listaDeFetos.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
//       <!-- <p v-if="!!infosCompartilhadas.transdutor">Exame realizado com transdutor {{infosCompartilhadas.transdutor}} de alta frequência. </p>

//       <p v-if="infosCompartilhadas.dataUltimaMestrucao">Data da última menstruação: {{this.dataFormatada(this.infosCompartilhadas.dataUltimaMestrucao)}} </p>

//       <p v-if="!laudo.incerta">Idade gestacional (DUM) {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias</p>

//       <p v-if="laudo.length == 1">Gestação única.</p>
//       <p v-if="laudo.length == 2">Gestação Gemelar.</p>
//       <p v-if="laudo.length == 3">Gestação Trigemelar.</p>
//       <p v-if="laudo.length == 4">Gestação Quadrigemelar.</p>
//       <p v-if="laudo.length == 4">Gestação quíntupla.</p>

//       <p v-if="laudo[0].placenta">Placenta {{laudo[0].placenta}} , grau 0. </p>

//       <p v-if="laudo[0].comprimentoCabecaNadegas">Comprimento cabeça-nádegas: {{laudo[0].comprimentoCabecaNadegas}} mm.</p>

//       <p v-if="laudo[0].frequenciaCardiacaFetal ">Frequencia Cardíaca fetal: {{laudo[0].frequenciaCardiacaFetal}} bpm.</p>
//       <p></p>
//       <p v-if="laudo[0].transulucenciaNucal || laudo[0].ossoNasal"><h4 v-if="laudo[0].transulucenciaNucal || laudo[0].ossoNasal">Marcadores Para Cromossomopatias</h4></p>

//       <p v-if="laudo[0].transulucenciaNucal">Translucência nucal: {{laudo[0].transulucenciaNucal}} mm.</p>

//       <p v-if="laudo[0].ossoNasal">Osso nasal {{laudo[0].ossoNasal}}.</p>

//       <p v-if="laudo[0].riscoTrissomiadois  || laudo[0].riscoTrissomiaum ">

//       <p><h4 v-if="laudo[0].riscoTrissomiadois  || laudo[0].riscoTrissomiaum ">Risco Para Trissomias</h4></p>

//       <p v-if="laudo[0].riscoTrissomiaum">Risco basal (baseado na idade materna):</p>

//       <p v-if="laudo[0].riscoTrissomiaum ">Trissomia do 21: {{laudo[0].riscoTrissomiaum}} mm.</p>
//       <p></p>
//       <p></p>

//       <p v-if="laudo[0].riscoTrissomiadois ">Risco corrigido (baseado na idade materna e TN):</p>

//       <p v-if="laudo[0].riscoTrissomiadois ">Trissomia do 21: {{laudo[0].riscoTrissomiadois}} mm.</p>
//       </folha>

//       <folha v-if="laudo[0].diametroBiparietalPrimeiroTrimestre">

//         <p v-if="!!laudo[0].diametroBiparietalPrimeiroTrimestre">Diâmetro biparietal {{laudo[0].diametroBiparietalPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência cefálica: {{laudo[0].circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência abdominal: {{laudo[0].circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

//         <p>Fêmur: {{laudo[0].femurPrimeiroTrimestre}}mm.</p>

//         <p>Peso: {{laudo[0].pesoPrimeiroTrimestre}}mm.</p>

//         <p>Morfologia fetal: </p>

//         <p>Crânio: {{laudo[0].cranioPrimeiroTrimestre}}</p>

//         <p>Sistema nervoso central: {{laudo[0].sistemaNervosoCentralPrimeiroTrimistre}}</p>

//         <p>Translucência intracraniana: {{laudo[0].translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="laudo[0].translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo[0].translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

//         <p>Órbitas: {{laudo[0].orbitasPrimeiroTrimistre}} </p>

//         <p>Perfil fetal: {{laudo[0].perfilFetalPrimeiroTrimistre}} </p>

//         <p>Estômago: {{laudo[0].estomagoPrimeiroTrimistre}} </p>

//         <p>Bexiga: {{laudo[0].bexigaPrimeiroTrimistre}} </p>

//         <p>Parede abdominal: {{laudo[0].paredeAbdominalPrimeiroTrimistre}} </p>

//         <p>Cordão umbilical: {{laudo[0].cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo[0].cordaoUmbilicalInseridoNa}}</p>

//         <p>Membros superiores {{laudo[0].membrosSuperioresPrimeiroTrimistre}}</p>

//         <p>Membros inferiores {{laudo[0].membrosInferioresPrimeiroTrimistre}}</p>

//         <p>Artérias renais {{laudo[0].artereiasRenaisPrimeiroTrimistre}}</p>
//         </folha>

//       <folha v-if="!!laudo[0].arteriaUterinaDireita || !!laudo[0].coloUterino">
//         <template v-if="!!laudo[0].arteriaUterinaDireita">
//           <p><h4>Risco para pré-eclâmpsia:</h4></p>

//           <p>Artéria uterina direita:</p>

//           <p>IP: {{laudo[0].arteriaUterinaDireita}}</p>

//           <p>Artéria uterina esquerda:</p>

//           <p>IP: {{laudo[0].arteriaUterinaEsquerda}}</p>

//           <p>Média do IP: {{laudo[0].medidaIP}} (Percentil {{laudo[0].percentil}} %)</p>

//           <p>Risco basal para pré-eclâmpsia - história materna - 1: {{laudo[0].riscoBasalPreEclampsia}}</p>

//           <p>Risco corrigido para pré-eclâmpsia - história materna, pressão arterial média e artéria uterina - 1: {{laudo[0].riscoCorrigidoPreEclampsia}}</p>
//         </template>

//         <template v-if="!!laudo[0].coloUterino">
//           <p><h4>Perfil Cervical:</h4></p>

//           <p>Colo uterino {{laudo[0].coloUterino}}, medindo {{laudo[0].coloMedidaEmRepouco}} cm em repouso.</p>

//           <p>Após 3 minutos de avaliação, o colo {{laudo[0].coloPassouAMedir}} {{laudo[0].cm}}cm.</p>

//           <p>Orifício cervical interno {{laudo[0].orificioCervicalInterno}} <span v-if="laudo[0].orificioCervicalInterno === 'aberto para'"> {{laudo[0].orificioCervicalInternoDois}}cm. </span></p>

//           <p>{{laudo[0].protusaoBolsaAmniotica}} de protrusão da bolsa amniótica no momento do exame.</p>

//           <p>Sinal de sludge {{laudo[0].sinalDeSludge}}</p>

//           <p>{{laudo[0].polipoEndocervicalMedindo}} <span v-if="laudo[0].polipoEndocervicalMedindo === 'Observamos pólipo endocervical medindo'"> {{laudo[0].polipoEndocervicalMedindoCm}}cm.</span></p>
//         </template>
//       </folha>

//       <folha>

//       <p><h4>Conclusão </h4></p>
//       <p v-if="laudo.length == 1">Gestação única</p>
//       <p v-if="laudo.length == 2">Gestação Gemelar</p>
//       <p v-if="laudo.length == 3">Gestação Trigemelar</p>
//       <p v-if="laudo.length == 4">Gestação Quadrigemelar</p>
//       <p v-if="laudo.length == 4">Gestação quíntupla</p>

//       <p v-if="!laudo.incerta">Idade gestacional baseada na biometria fetal atual de {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias,</p>
//       <p v-if="!laudo.incerta"> {{laudo[0].compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

//       <p v-if="laudo[0].dataProvavelParto">Data provável do parto: {{this.dataFormatada(this.laudo[0].dataProvavelParto)}} </p>

//       <p v-if="laudo[0].riscoTrissomia">O risco de trissomia do 13, 18 e 21 {{laudo[0].riscoTrissomia}} após a  medida da TN no feto</p>
//       <p> avaliado. </p>

//       <p v-if="laudo[0].taxaRisco">Sendo considerado de {{laudo[0].taxaRisco}} para cromossomopatias.</p>

//       <p v-if="laudo[0].morfologiafetalIdadeGestacional">{{laudo[0].morfologiafetalIdadeGestacional}} <span v-if="laudo[0].morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'">{{laudo[0].morfologiafetalIdadeGestacionaUmOutros}}</span></p>

//       <p>{{laudo[0].conclusaoTres}}</p>

//       <p v-if="laudo[0].coloUterinoConclusao"> Colo uterino {{laudo[0].coloUterinoConclusao}}</p>

//       <p v-if="laudo[0].riscoColoUterino">De acordo com o Estudo ASPRE, mulheres identificadas
//          pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
//           devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.
//         </p>

//       <p v-if="laudo[0].comentariosAdcionaisFinalUm"> Comentários Adcionais: {{laudo[0].comentariosAdcionaisFinalUm}}</p>

//       <p></p>
//       <p><h4>Notas: </h4></p>

//       <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco</p>
//       <p> corrigido pela TN em:</p>
//       <p>■ Baixo o risco, o risco corrigido &lt; 1:1000</p>
//       <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
//       <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
//       <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
//       <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto</p>
//       <p> venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
//       <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo</p>
//       <p> corial ou amniocentese).</p>

//       <p v-if="!!laudo[0].notasFinalUm"> Notas: {{laudo[0].notasFinalUm}}</p>

//       </folha>

//       <template v-if="laudo.length>= 2">
//       <folha>
//         <p>2° Gestação</p>

//       <p>Exame realizado com transdutor {{infosCompartilhadas.transdutor}} de alta frequência. </p>

//       <p>Data da última menstruação: {{this.dataFormatada(this.infosCompartilhadas.dataUltimaMestrucao)}} </p>

//       <p v-if="!laudo.incerta">Idade gestacional (DUM): {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias</p>

//       <p>Gestação: Gemelar, situação variável ao exame. </p>

//       <p>Placenta {{laudo[1].placenta}} , grau 0. </p>

//       <p>Comprimento cabeça-nádegas: {{laudo[1].comprimentoCabecaNadegas}} mm.</p>

//       <p>Frequencia Cardíaca fetal: {{laudo[1].frequenciaCardiacaFetal}} bpm.</p>

//       <p><h4>Marcadores Para Cromossomopatias</h4></p>

//       <p>Translucência nucal: {{laudo[1].transulucenciaNucal}} mm.</p>

//       <p>Osso nasal {{laudo[1].ossoNasal}}</p>

//       <p><h4>Risco Para Trissomias:</h4></p>

//       <p>Risco basal (baseado na idade materna):</p>

//       <p>Trissomia do 21: {{laudo[1].riscoTrissomiaum}} mm.</p>

//       <p>Risco corrigido (baseado na idade materna e TN):</p>

//       <p>Trissomia do 21: {{laudo[1].riscoTrissomiadois}} mm.</p>
//       </folha>

//       <folha v-if="!!laudo[1].diametroBiparietalPrimeiroTrimestre">

//         <p>Diâmetro biparietal {{laudo[1].diametroBiparietalPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência cefálica: {{laudo[1].circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência abdominal: {{laudo[1].circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

//         <p>Fêmur: {{laudo[1].femurPrimeiroTrimestre}}mm.</p>

//         <p>Peso: {{laudo[1].pesoPrimeiroTrimestre}}mm.</p>

//         <p>Morfologia fetal: </p>

//         <p>Crânio: {{laudo[1].cranioPrimeiroTrimestre}}</p>

//         <p>Sistema nervoso central: {{laudo[1].sistemaNervosoCentralPrimeiroTrimistre}}</p>

//         <p>Translucência intracraniana: {{laudo[1].translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="laudo[1].translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo[1].translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

//         <p>Órbitas: {{laudo[1].orbitasPrimeiroTrimistre}} </p>

//         <p>Perfil fetal: {{laudo[1].perfilFetalPrimeiroTrimistre}} </p>

//         <p>Estômago: {{laudo[1].estomagoPrimeiroTrimistre}} </p>

//         <p>Bexiga: {{laudo[1].bexigaPrimeiroTrimistre}} </p>

//         <p>Parede abdominal: {{laudo[1].paredeAbdominalPrimeiroTrimistre}} </p>

//         <p>Cordão umbilical: {{laudo[1].cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo[1].cordaoUmbilicalInseridoNa}}</p>

//         <p>Membros superiores {{laudo[1].membrosSuperioresPrimeiroTrimistre}}</p>

//         <p>Membros inferiores {{laudo[1].membrosInferioresPrimeiroTrimistre}}</p>

//         <p>Artérias renais {{laudo[1].artereiasRenaisPrimeiroTrimistre}}</p>
//         </folha>

//       <folha v-if="!!laudo[1].arteriaUterinaDireita || !!laudo[1].coloUterino">
//         <template v-if="!!laudo[1].arteriaUterinaDireita">
//           <p><h4>Risco para pré-eclâmpsia:</h4></p>

//           <p>Artéria uterina direita:</p>

//           <p>IP: {{laudo[1].arteriaUterinaDireita}}</p>

//           <p>Artéria uterina esquerda:</p>

//           <p>IP: {{laudo[1].arteriaUterinaEsquerda}}</p>

//           <p>Média do IP: {{laudo[1].medidaIP}} (Percentil {{laudo[1].percentil}} %)</p>

//           <p>Risco basal para pré-eclâmpsia - história materna - 1: {{laudo[1].riscoBasalPreEclampsia}}</p>

//           <p>Risco corrigido para pré-eclâmpsia - história materna, pressão arterial média e artéria uterina - 1: {{laudo[1].riscoCorrigidoPreEclampsia}}</p>
//         </template>

//         <template v-if="!!laudo[1].coloUterino">
//           <p><h4>Perfil Cervical:</h4></p>

//           <p>Colo uterino {{laudo[1].coloUterino}}, medindo {{laudo[1].coloMedidaEmRepouco}} cm em repouso.</p>

//           <p>Após 3 minutos de avaliação, o colo {{laudo[1].coloPassouAMedir}} {{laudo[1].cm}}cm.</p>

//           <p>Orifício cervical interno {{laudo[1].orificioCervicalInterno}} <span v-if="laudo[1].orificioCervicalInterno === 'aberto para'"> {{laudo[1].orificioCervicalInternoDois}}cm. </span></p>

//           <p>{{laudo[1].protusaoBolsaAmniotica}} de protrusão da bolsa amniótica no momento do exame.</p>

//           <p>Sinal de sludge {{laudo[1].sinalDeSludge}}</p>

//           <p>{{laudo[1].polipoEndocervicalMedindo}} <span v-if="laudo[1].polipoEndocervicalMedindo === 'Observamos pólipo endocervical medindo'"> {{laudo[1].polipoEndocervicalMedindoCm}}cm.</span></p>
//         </template>
//       </folha>

//       <folha>

//       <p><h4>Conclusão </h4></p>

//       <p>Gestação: {{infosCompartilhadas.gestacaoDois}}{{infosCompartilhadas.gestacao}}

//           <span v-if="infosCompartilhadas.gestacaoDois === 'gemelar'">
//               {{infosCompartilhadas.gestacaoTres}} {{infosCompartilhadas.gestacaoQuatro}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'trigemelar'">
//               {{ infosCompartilhadas.comentariosAdcionaisTrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quadrigemelar'">
//               {{infosCompartilhadas.comentariosAdcionaisQuadrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quíntupla'">
//               {{infosCompartilhadas.comentariosAdcionaisQuintupla}}
//           </span>
//       </p>

//       <p>Idade gestacional baseada na biometria fetal atual de {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias, {{laudo[1].compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

//       <p>Data provável do parto: {{this.dataFormatada(this.laudo[0].dataProvavelParto)}}  </p>

//       <p>O risco de trissomia do 13, 18 e 21 {{laudo[1].riscoTrissomia}} após a medida da TN no feto avaliado. </p>

//       <p>Sendo considerado de {{laudo[1].taxaRisco}} para cromossomopatias.</p>

//       <p>{{laudo[1].morfologiafetalIdadeGestacional}} <span v-if="laudo[1].morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'">{{laudo[1].morfologiafetalIdadeGestacionaUmOutros}}</span></p>

//       <p>{{laudo[1].conclusaoTres}}</p>

//       <p v-if="!!laudo[1].coloUterinoConclusao"> Colo uterino {{laudo[1].coloUterinoConclusao}}</p>

//       <p v-if="!!laudo[1].riscoColoUterino">De acordo com o Estudo ASPRE, mulheres identificadas
//          pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
//           devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.
//         </p>

//       <p v-if="!!laudo[1].comentariosAdcionaisFinalUm"> Comentários Adcionais: {{laudo[1].comentariosAdcionaisFinalUm}}</p>

//       </folha>

//       <folha>

//       <p><h4>Notas: </h4></p>

//       <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco corrigido pela TN em:</p>
//       <p>■ Baixo o risco, o risco corrigido &lt; 1:1000</p>
//       <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
//       <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
//       <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
//       <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
//       <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo corial ou amniocentese).</p>

//       <p v-if="!!laudo[1].notasFinalUm"> Notas: {{laudo[1].notasFinalUm}}</p>

//       </folha>
//       </template>

//       <template v-if="laudo.length>= 3">
//       <folha>
//         <p>3° Gestação</p>

//       <p>Exame realizado com transdutor {{infosCompartilhadas.transdutor}} de alta frequência. </p>

//       <p>Data da última menstruação: {{this.dataFormatada(this.infosCompartilhadas.dataUltimaMestrucao)}} </p>

//       <p v-if="!laudo.incerta">Idade gestacional (DUM): {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias</p>

//       <p>Gestação: Trigemelar, situação variável ao exame. </p>

//       <p>Placenta {{laudo[2].placenta}} , grau 0. </p>

//       <p>Comprimento cabeça-nádegas: {{laudo[2].comprimentoCabecaNadegas}} mm.</p>

//       <p>Frequencia Cardíaca fetal: {{laudo[2].frequenciaCardiacaFetal}} bpm.</p>

//       <p><h4>Marcadores Para Cromossomopatias</h4></p>

//       <p>Translucência nucal: {{laudo[2].transulucenciaNucal}} mm.</p>

//       <p>Osso nasal {{laudo[2].ossoNasal}}</p>

//       <p><h4>Risco Para Trissomias:</h4></p>

//       <p>Risco basal (baseado na idade materna):</p>

//       <p>Trissomia do 21: {{laudo[2].riscoTrissomiaum}} mm.</p>

//       <p>Risco corrigido (baseado na idade materna e TN):</p>

//       <p>Trissomia do 21: {{laudo[2].riscoTrissomiadois}} mm.</p>
//       </folha>

//       <folha v-if="!!laudo[2].diametroBiparietalPrimeiroTrimestre">

//         <p>Diâmetro biparietal {{laudo[2].diametroBiparietalPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência cefálica: {{laudo[2].circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência abdominal: {{laudo[2].circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

//         <p>Fêmur: {{laudo[2].femurPrimeiroTrimestre}}mm.</p>

//         <p>Peso: {{laudo[2].pesoPrimeiroTrimestre}}mm.</p>

//         <p>Morfologia fetal: </p>

//         <p>Crânio: {{laudo[2].cranioPrimeiroTrimestre}}</p>

//         <p>Sistema nervoso central: {{laudo[2].sistemaNervosoCentralPrimeiroTrimistre}}</p>

//         <p>Translucência intracraniana: {{laudo[2].translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="laudo[2].translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo[2].translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

//         <p>Órbitas: {{laudo[2].orbitasPrimeiroTrimistre}} </p>

//         <p>Perfil fetal: {{laudo[2].perfilFetalPrimeiroTrimistre}} </p>

//         <p>Estômago: {{laudo[2].estomagoPrimeiroTrimistre}} </p>

//         <p>Bexiga: {{laudo[2].bexigaPrimeiroTrimistre}} </p>

//         <p>Parede abdominal: {{laudo[2].paredeAbdominalPrimeiroTrimistre}} </p>

//         <p>Cordão umbilical: {{laudo[2].cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo[2].cordaoUmbilicalInseridoNa}}</p>

//         <p>Membros superiores {{laudo[2].membrosSuperioresPrimeiroTrimistre}}</p>

//         <p>Membros inferiores {{laudo[2].membrosInferioresPrimeiroTrimistre}}</p>

//         <p>Artérias renais {{laudo[2].artereiasRenaisPrimeiroTrimistre}}</p>
//         </folha>

//       <folha v-if="!!laudo[2].arteriaUterinaDireita || !!laudo[2].coloUterino">
//         <template v-if="!!laudo[2].arteriaUterinaDireita">
//           <p><h4>Risco para pré-eclâmpsia:</h4></p>

//           <p>Artéria uterina direita:</p>

//           <p>IP: {{laudo[2].arteriaUterinaDireita}}</p>

//           <p>Artéria uterina esquerda:</p>

//           <p>IP: {{laudo[2].arteriaUterinaEsquerda}}</p>

//           <p>Média do IP: {{laudo[2].medidaIP}} (Percentil {{laudo[2].percentil}} %)</p>

//           <p>Risco basal para pré-eclâmpsia - história materna - 1: {{laudo[2].riscoBasalPreEclampsia}}</p>

//           <p>Risco corrigido para pré-eclâmpsia - história materna, pressão arterial média e artéria uterina - 1: {{laudo[2].riscoCorrigidoPreEclampsia}}</p>
//         </template>

//         <template v-if="!!laudo[2].coloUterino">
//           <p><h4>Perfil Cervical:</h4></p>

//           <p>Colo uterino {{laudo[2].coloUterino}}, medindo {{laudo[2].coloMedidaEmRepouco}} cm em repouso.</p>

//           <p>Após 3 minutos de avaliação, o colo {{laudo[2].coloPassouAMedir}} {{laudo[2].cm}}cm.</p>

//           <p>Orifício cervical interno {{laudo[2].orificioCervicalInterno}} <span v-if="laudo[2].orificioCervicalInterno === 'aberto para'"> {{laudo[2].orificioCervicalInternoDois}}cm. </span></p>

//           <p>{{laudo[2].protusaoBolsaAmniotica}} de protrusão da bolsa amniótica no momento do exame.</p>

//           <p>Sinal de sludge {{laudo[2].sinalDeSludge}}</p>

//           <p>{{laudo[2].polipoEndocervicalMedindo}} <span v-if="laudo[2].polipoEndocervicalMedindo === 'Observamos pólipo endocervical medindo'"> {{laudo[2].polipoEndocervicalMedindoCm}}cm.</span></p>
//         </template>
//       </folha>

//       <folha>

//       <p><h4>Conclusão </h4></p>

//       <p>Gestação: {{infosCompartilhadas.gestacaoDois}}{{infosCompartilhadas.gestacao}}

//           <span v-if="infosCompartilhadas.gestacaoDois === 'gemelar'">
//               {{infosCompartilhadas.gestacaoTres}} {{infosCompartilhadas.gestacaoQuatro}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'trigemelar'">
//               {{ infosCompartilhadas.comentariosAdcionaisTrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quadrigemelar'">
//               {{infosCompartilhadas.comentariosAdcionaisQuadrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quíntupla'">
//               {{infosCompartilhadas.comentariosAdcionaisQuintupla}}
//           </span>
//       </p>

//       <p>Idade gestacional baseada na biometria fetal atual de {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias, {{laudo[2].compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

//       <p>Data provável do parto: {{this.dataFormatada(this.laudo[0].dataProvavelParto)}}  </p>

//       <p>O risco de trissomia do 13, 18 e 21 {{laudo[2].riscoTrissomia}} após a medida da TN no feto avaliado. </p>

//       <p>Sendo considerado de {{laudo[2].taxaRisco}} para cromossomopatias.</p>

//       <p>{{laudo[2].morfologiafetalIdadeGestacional}} <span v-if="laudo[2].morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'">{{laudo[2].morfologiafetalIdadeGestacionaUmOutros}}</span></p>

//       <p>{{laudo[2].conclusaoTres}}</p>

//       <p v-if="!!laudo[2].coloUterinoConclusao"> Colo uterino {{laudo[2].coloUterinoConclusao}}</p>

//       <p v-if="!!laudo[2].riscoColoUterino">De acordo com o Estudo ASPRE, mulheres identificadas
//          pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
//           devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.
//         </p>

//       <p v-if="!!laudo[2].comentariosAdcionaisFinalUm"> Comentários Adcionais: {{laudo[2].comentariosAdcionaisFinalUm}}</p>

//       </folha>

//       <folha>

//       <p><h4>Notas: </h4></p>

//       <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco corrigido pela TN em:</p>
//       <p>■ Baixo o risco, o risco corrigido &lt; 1:1000</p>
//       <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
//       <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
//       <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
//       <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
//       <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo corial ou amniocentese).</p>

//       <p v-if="!!laudo[2].notasFinalUm"> Notas: {{laudo[2].notasFinalUm}}</p>

//       </folha>
//       </template>

//       <template v-if="laudo.length>= 4">
//       <folha>
//         <p>4° Gestação</p>

//       <p>Exame realizado com transdutor {{infosCompartilhadas.transdutor}} de alta frequência. </p>

//       <p>Data da última menstruação: {{this.dataFormatada(this.infosCompartilhadas.dataUltimaMestrucao)}} </p>

//       <p v-if="!laudo.incerta">Idade gestacional (DUM): {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias</p>

//       <p>Gestação: Quadrigemelar, situação variável ao exame. </p>

//       <p>Placenta {{laudo[3].placenta}} , grau 0. </p>

//       <p>Comprimento cabeça-nádegas: {{laudo[3].comprimentoCabecaNadegas}} mm.</p>

//       <p>Frequencia Cardíaca fetal: {{laudo[3].frequenciaCardiacaFetal}} bpm.</p>

//       <p><h4>Marcadores Para Cromossomopatias</h4></p>

//       <p>Translucência nucal: {{laudo[3].transulucenciaNucal}} mm.</p>

//       <p>Osso nasal {{laudo[3].ossoNasal}}</p>

//       <p><h4>Risco Para Trissomias:</h4></p>

//       <p>Risco basal (baseado na idade materna):</p>

//       <p>Trissomia do 21: {{laudo[3].riscoTrissomiaum}} mm.</p>

//       <p>Risco corrigido (baseado na idade materna e TN):</p>

//       <p>Trissomia do 21: {{laudo[3].riscoTrissomiadois}} mm.</p>
//       </folha>

//       <folha v-if="!!laudo[3].diametroBiparietalPrimeiroTrimestre">

//         <p>Diâmetro biparietal {{laudo[3].diametroBiparietalPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência cefálica: {{laudo[3].circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência abdominal: {{laudo[3].circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

//         <p>Fêmur: {{laudo[3].femurPrimeiroTrimestre}}mm.</p>

//         <p>Peso: {{laudo[3].pesoPrimeiroTrimestre}}mm.</p>

//         <p>Morfologia fetal: </p>

//         <p>Crânio: {{laudo[3].cranioPrimeiroTrimestre}}</p>

//         <p>Sistema nervoso central: {{laudo[3].sistemaNervosoCentralPrimeiroTrimistre}}</p>

//         <p>Translucência intracraniana: {{laudo[3].translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="laudo[3].translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo[3].translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

//         <p>Órbitas: {{laudo[3].orbitasPrimeiroTrimistre}} </p>

//         <p>Perfil fetal: {{laudo[3].perfilFetalPrimeiroTrimistre}} </p>

//         <p>Estômago: {{laudo[3].estomagoPrimeiroTrimistre}} </p>

//         <p>Bexiga: {{laudo[3].bexigaPrimeiroTrimistre}} </p>

//         <p>Parede abdominal: {{laudo[3].paredeAbdominalPrimeiroTrimistre}} </p>

//         <p>Cordão umbilical: {{laudo[3].cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo[3].cordaoUmbilicalInseridoNa}}</p>

//         <p>Membros superiores {{laudo[3].membrosSuperioresPrimeiroTrimistre}}</p>

//         <p>Membros inferiores {{laudo[3].membrosInferioresPrimeiroTrimistre}}</p>

//         <p>Artérias renais {{laudo[3].artereiasRenaisPrimeiroTrimistre}}</p>
//         </folha>

//       <folha v-if="!!laudo[3].arteriaUterinaDireita || !!laudo[3].coloUterino">
//         <template v-if="!!laudo[3].arteriaUterinaDireita">
//           <p><h4>Risco para pré-eclâmpsia:</h4></p>

//           <p>Artéria uterina direita:</p>

//           <p>IP: {{laudo[3].arteriaUterinaDireita}}</p>

//           <p>Artéria uterina esquerda:</p>

//           <p>IP: {{laudo[3].arteriaUterinaEsquerda}}</p>

//           <p>Média do IP: {{laudo[3].medidaIP}} (Percentil {{laudo[3].percentil}} %)</p>

//           <p>Risco basal para pré-eclâmpsia - história materna - 1: {{laudo[3].riscoBasalPreEclampsia}}</p>

//           <p>Risco corrigido para pré-eclâmpsia - história materna, pressão arterial média e artéria uterina - 1: {{laudo[3].riscoCorrigidoPreEclampsia}}</p>
//         </template>

//         <template v-if="!!laudo[3].coloUterino">
//           <p><h4>Perfil Cervical:</h4></p>

//           <p>Colo uterino {{laudo[3].coloUterino}}, medindo {{laudo[3].coloMedidaEmRepouco}} cm em repouso.</p>

//           <p>Após 3 minutos de avaliação, o colo {{laudo[3].coloPassouAMedir}} {{laudo[3].cm}}cm.</p>

//           <p>Orifício cervical interno {{laudo[3].orificioCervicalInterno}} <span v-if="laudo[3].orificioCervicalInterno === 'aberto para'"> {{laudo[3].orificioCervicalInternoDois}}cm. </span></p>

//           <p>{{laudo[3].protusaoBolsaAmniotica}} de protrusão da bolsa amniótica no momento do exame.</p>

//           <p>Sinal de sludge {{laudo[3].sinalDeSludge}}</p>

//           <p>{{laudo[3].polipoEndocervicalMedindo}} <span v-if="laudo[3].polipoEndocervicalMedindo === 'Observamos pólipo endocervical medindo'"> {{laudo[3].polipoEndocervicalMedindoCm}}cm.</span></p>
//         </template>
//       </folha>

//       <folha>

//       <p><h4>Conclusão </h4></p>

//       <p>Gestação: {{infosCompartilhadas.gestacaoDois}}{{infosCompartilhadas.gestacao}}

//           <span v-if="infosCompartilhadas.gestacaoDois === 'gemelar'">
//               {{infosCompartilhadas.gestacaoTres}} {{infosCompartilhadas.gestacaoQuatro}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'trigemelar'">
//               {{ infosCompartilhadas.comentariosAdcionaisTrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quadrigemelar'">
//               {{infosCompartilhadas.comentariosAdcionaisQuadrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quíntupla'">
//               {{infosCompartilhadas.comentariosAdcionaisQuintupla}}
//           </span>
//       </p>

//       <p>Idade gestacional baseada na biometria fetal atual de {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias, {{laudo[3].compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

//       <p>Data provável do parto: {{this.dataFormatada(this.laudo[0].dataProvavelParto)}}  </p>

//       <p>O risco de trissomia do 13, 18 e 21 {{laudo[3].riscoTrissomia}} após a medida da TN no feto avaliado. </p>

//       <p>Sendo considerado de {{laudo[3].taxaRisco}} para cromossomopatias.</p>

//       <p>{{laudo[3].morfologiafetalIdadeGestacional}} <span v-if="laudo[3].morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'">{{laudo[3].morfologiafetalIdadeGestacionaUmOutros}}</span></p>

//       <p>{{laudo[3].conclusaoTres}}</p>

//       <p v-if="!!laudo[3].coloUterinoConclusao"> Colo uterino {{laudo[3].coloUterinoConclusao}}</p>

//       <p v-if="!!laudo[3].riscoColoUterino">De acordo com o Estudo ASPRE, mulheres identificadas
//          pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
//           devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.
//         </p>

//       <p v-if="!!laudo[3].comentariosAdcionaisFinalUm"> Comentários Adcionais: {{laudo[3].comentariosAdcionaisFinalUm}}</p>

//       </folha>

//       <folha>

//       <p><h4>Notas: </h4></p>

//       <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco corrigido pela TN em:</p>
//       <p>■ Baixo o risco, o risco corrigido &lt; 1:1000</p>
//       <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
//       <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
//       <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
//       <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
//       <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo corial ou amniocentese).</p>

//       <p v-if="!!laudo[3].notasFinalUm"> Notas: {{laudo[3].notasFinalUm}}</p>

//       </folha>
//       </template>

//       <template v-if="laudo.length>= 4">
//       <folha>
//         <p>5° Gestação</p>

//       <p>Exame realizado com transdutor {{infosCompartilhadas.transdutor}} de alta frequência. </p>

//       <p>Data da última menstruação: {{this.dataFormatada(this.infosCompartilhadas.dataUltimaMestrucao)}} </p>

//       <p v-if="!laudo.incerta">Idade gestacional (DUM): {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias</p>

//       <p>Gestação: Quíntupla, situação variável ao exame. </p>

//       <p>Placenta {{laudo[4].placenta}} , grau 0. </p>

//       <p>Comprimento cabeça-nádegas: {{laudo[4].comprimentoCabecaNadegas}} mm.</p>

//       <p>Frequencia Cardíaca fetal: {{laudo[4].frequenciaCardiacaFetal}} bpm.</p>

//       <p><h4>Marcadores Para Cromossomopatias</h4></p>

//       <p>Translucência nucal: {{laudo[4].transulucenciaNucal}} mm.</p>

//       <p>Osso nasal {{laudo[4].ossoNasal}}</p>

//       <p><h4>Risco Para Trissomias:</h4></p>

//       <p>Risco basal (baseado na idade materna):</p>

//       <p>Trissomia do 21: {{laudo[4].riscoTrissomiaum}} mm.</p>

//       <p>Risco corrigido (baseado na idade materna e TN):</p>

//       <p>Trissomia do 21: {{laudo[4].riscoTrissomiadois}} mm.</p>
//       </folha>

//       <folha v-if="!!laudo[4].diametroBiparietalPrimeiroTrimestre">

//         <p>Diâmetro biparietal {{laudo[4].diametroBiparietalPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência cefálica: {{laudo[4].circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

//         <p>Circunferência abdominal: {{laudo[4].circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

//         <p>Fêmur: {{laudo[4].femurPrimeiroTrimestre}}mm.</p>

//         <p>Peso: {{laudo[4].pesoPrimeiroTrimestre}}mm.</p>

//         <p>Morfologia fetal: </p>

//         <p>Crânio: {{laudo[4].cranioPrimeiroTrimestre}}</p>

//         <p>Sistema nervoso central: {{laudo[4].sistemaNervosoCentralPrimeiroTrimistre}}</p>

//         <p>Translucência intracraniana: {{laudo[4].translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="laudo[4].translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo[4].translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

//         <p>Órbitas: {{laudo[4].orbitasPrimeiroTrimistre}} </p>

//         <p>Perfil fetal: {{laudo[4].perfilFetalPrimeiroTrimistre}} </p>

//         <p>Estômago: {{laudo[4].estomagoPrimeiroTrimistre}} </p>

//         <p>Bexiga: {{laudo[4].bexigaPrimeiroTrimistre}} </p>

//         <p>Parede abdominal: {{laudo[4].paredeAbdominalPrimeiroTrimistre}} </p>

//         <p>Cordão umbilical: {{laudo[4].cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo[4].cordaoUmbilicalInseridoNa}}</p>

//         <p>Membros superiores {{laudo[4].membrosSuperioresPrimeiroTrimistre}}</p>

//         <p>Membros inferiores {{laudo[4].membrosInferioresPrimeiroTrimistre}}</p>

//         <p>Artérias renais {{laudo[4].artereiasRenaisPrimeiroTrimistre}}</p>
//         </folha>

//       <folha v-if="!!laudo[4].arteriaUterinaDireita || !!laudo[4].coloUterino">
//         <template v-if="!!laudo[4].arteriaUterinaDireita">
//           <p><h4>Risco para pré-eclâmpsia:</h4></p>

//           <p>Artéria uterina direita:</p>

//           <p>IP: {{laudo[4].arteriaUterinaDireita}}</p>

//           <p>Artéria uterina esquerda:</p>

//           <p>IP: {{laudo[4].arteriaUterinaEsquerda}}</p>

//           <p>Média do IP: {{laudo[4].medidaIP}} (Percentil {{laudo[4].percentil}} %)</p>

//           <p>Risco basal para pré-eclâmpsia - história materna - 1: {{laudo[4].riscoBasalPreEclampsia}}</p>

//           <p>Risco corrigido para pré-eclâmpsia - história materna, pressão arterial média e artéria uterina - 1: {{laudo[4].riscoCorrigidoPreEclampsia}}</p>
//         </template>

//         <template v-if="!!laudo[4].coloUterino">
//           <p><h4>Perfil Cervical:</h4></p>

//           <p>Colo uterino {{laudo[4].coloUterino}}, medindo {{laudo[4].coloMedidaEmRepouco}} cm em repouso.</p>

//           <p>Após 3 minutos de avaliação, o colo {{laudo[4].coloPassouAMedir}} {{laudo[4].cm}}cm.</p>

//           <p>Orifício cervical interno {{laudo[4].orificioCervicalInterno}} <span v-if="laudo[4].orificioCervicalInterno === 'aberto para'"> {{laudo[4].orificioCervicalInternoDois}}cm. </span></p>

//           <p>{{laudo[4].protusaoBolsaAmniotica}} de protrusão da bolsa amniótica no momento do exame.</p>

//           <p>Sinal de sludge {{laudo[4].sinalDeSludge}}</p>

//           <p>{{laudo[4].polipoEndocervicalMedindo}} <span v-if="laudo[4].polipoEndocervicalMedindo === 'Observamos pólipo endocervical medindo'"> {{laudo[4].polipoEndocervicalMedindoCm}}cm.</span></p>
//         </template>
//       </folha>

//       <folha>

//       <p><h4>Conclusão: </h4></p>

//       <p>Gestação: {{infosCompartilhadas.gestacaoDois}}{{infosCompartilhadas.gestacao}}

//           <span v-if="infosCompartilhadas.gestacaoDois === 'gemelar'">
//               {{infosCompartilhadas.gestacaoTres}} {{infosCompartilhadas.gestacaoQuatro}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'trigemelar'">
//               {{ infosCompartilhadas.comentariosAdcionaisTrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quadrigemelar'">
//               {{infosCompartilhadas.comentariosAdcionaisQuadrigemelar}}
//           </span>

//           <span v-if="infosCompartilhadas.gestacaoDois === 'quíntupla'">
//               {{infosCompartilhadas.comentariosAdcionaisQuintupla}}
//           </span>
//       </p>

//       <p>Idade gestacional baseada na biometria fetal atual de {{infosCompartilhadas.semanasDUM}} semanas e {{infosCompartilhadas.diasDUM}} dias, {{laudo[4].compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

//       <p>Data provável do parto: {{this.dataFormatada(this.laudo[0].dataProvavelParto)}}  </p>

//       <p>O risco de trissomia do 13, 18 e 21 {{laudo[4].riscoTrissomia}} após a medida da TN no feto avaliado. </p>

//       <p>Sendo considerado de {{laudo[4].taxaRisco}} para cromossomopatias.</p>

//       <p>{{laudo[4].morfologiafetalIdadeGestacional}} <span v-if="laudo[4].morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'">{{laudo[4].morfologiafetalIdadeGestacionaUmOutros}}</span></p>

//       <p>{{laudo[4].conclusaoTres}}</p>

//       <p v-if="!!laudo[4].coloUterinoConclusao"> Colo uterino {{laudo[4].coloUterinoConclusao}}</p>

//       <p v-if="!!laudo[4].riscoColoUterino">De acordo com o Estudo ASPRE, mulheres identificadas
//          pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
//           devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.
//         </p>

//       <p v-if="!!laudo[4].comentariosAdcionaisFinalUm"> Comentários Adcionais: {{laudo[4].comentariosAdcionaisFinalUm}}</p>

//       </folha>

//       <folha>

//       <p><h4>Notas: </h4></p>

//       <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco corrigido pela TN em:</p>
//       <p>■ Baixo o risco, o risco corrigido &lt; 1:1000</p>
//       <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
//       <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
//       <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
//       <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
//       <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo corial ou amniocentese).</p>

//       <p v-if="!!laudo[4].notasFinalUm"> Notas: {{laudo[4].notasFinalUm}}</p>

//       </folha>
//       </template>

//   </section>
// </template> -->
import ImprecoesMixins from '@mixins/ImprecoesMixins';
import moment from 'moment';

export default {
  mixins: [ImprecoesMixins],
  computed: {
    title() {
      const titles = ['TRANSLUCÊNCIA NUCAL'];

      if (!!this.laudo[0]?.coloUterino) titles.push('PERFIL CERVICAL');

      if (!!this.laudo[0]?.arteriaUterinaDireita) titles.push('DOPPLER');

      if (!!this.laudo[0]?.diametroBiparietalPrimeiroTrimestre)
        titles.push('AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE');

      return titles;
    },
  },

  methods: {
    dataFormatada(val) {
      return moment(val).format('DD-MM-YYYY');
    },
  },

  mounted() {
    if (this.$route.query.via) this.via = this.$route.query.via;
    let folha = [];
    const todos = [];
    let arrayDeFetos = [];

    for (let i = 0; i < this.laudo.length; i++) {
      
      folha.push(`História materna`);
      if (this.laudo[i].dataNascimento)
        folha.push(`Data de nascimento : ${this.dataFormatada(this.laudo[i].dataNascimento)}.`);
        if (this.laudo[i].anos || this.laudo[i].meses)
        folha.push(`${this.laudo[i].anos?this.laudo[i].anos + (this.laudo[i].anos > 1 ? ' anos':' ano'):''}
        ${this.laudo[i].anos && this.laudo[i].meses? ' e ':''}
        ${this.laudo[i].meses?this.laudo[i].meses + (this.laudo[i].meses > 1 ? ' meses':' mês'):''}
      
        .`);
        if (this.laudo[i].peso)
        folha.push(`${this.laudo[i].peso?"Peso: " + this.laudo[i].peso + ' kg.':""}${
      this.laudo[i].altura?" Altura: " + this.laudo[i].altura + ' cm.':""}${
      this.laudo[i].imc?" IMC: " + this.laudo[i].imc :""}.`);
      if (this.laudo[i].origemRacial)
        folha.push(`Origem racial: ${this.laudo[i].origemRacial}.`);
        if (this.laudo[i].metodoConcepcao)
        folha.push(`Método de concepção: ${this.laudo[i].metodoConcepcao}.`);
        if (this.laudo[i].fuma)
        folha.push(`Fumar durante a gravidez: ${this.laudo[i].fuma}.`);
        if (this.laudo[i].hipertensao)
        folha.push(`Hipertensão crônica: ${this.laudo[i].hipertensao}.`);
        if (this.laudo[i].diabetes)
        folha.push(`Diabetes : ${this.laudo[i].diabetes}${
      this.laudo[i].diabetes == 'Sim'? ',  '+ this.laudo[i].tipoDiabetes :''}.`);

        if (this.laudo[i].diabeteTipoUm)
        folha.push(`Diabetes tipo I: ${this.laudo[i].diabeteTipoUm}.`);
        if (this.laudo[i].diabeteTipoDois)
        folha.push(`Diabetes tipo II: ${this.laudo[i].diabeteTipoDois}.`);
        if (this.laudo[i].diabeteTipoGestacional)
        folha.push(`Diabetes gestacional: ${this.laudo[i].diabeteTipoGestacional}.`);
        if (this.laudo[i].lupus)
        folha.push(`Lúpus eritematoso sistêmico:  ${this.laudo[i].lupus}.`);
        if (this.laudo[i].sindromeAnti)
        folha.push(`Síndrome antifosfolipídica:  ${this.laudo[i].sindromeAnti}.`);
        if (this.laudo[i].historicoFamiliarDiabetes)
        folha.push(`História familiar de diabetes:  ${this.laudo[i].historicoFamiliarDiabetes}.`);
        if(this.laudo[i].historiaObstetricaAnterior){

        if(this.laudo[i].historiaObstetricaAnterior.length>0)
        folha.push("História obstétrica anterior")
        for (let j = 0; j < this.laudo[i].historiaObstetricaAnterior.length; j++) {
          folha.push(this.laudo[i].historiaObstetricaAnterior[j]+'.');
        }
      }
      if(this.laudo[i].perdasGestacionais){
        if(this.laudo[i].perdasGestacionais.length>0)
        folha.push("Perdas gestacionais")
        for (let k = 0; k < this.laudo[i].perdasGestacionais.length; k++) {
          folha.push(this.laudo[i].perdasGestacionais[k]+'.');
         
        }
      }






      if (this.laudo[i].indicacaoExame)
        folha.push(`Indicação do exame: ${this.laudo[i].indicacaoExame}.`);
      
        

      if (this.infosCompartilhadas.transdutor && i == 0)
        folha.push(
          `Exame realizado com transdutor ${this.infosCompartilhadas.transdutor} de alta resolução.`
        );

      if (this.infosCompartilhadas.dataUltimaMestrucao && i == 0)
        folha.push(
          `Data da última menstruação: ${this.dataFormatada(
            this.infosCompartilhadas.dataUltimaMestrucao
          )}.`
        );

      //folha.push(`espaço`);
      if ((this.laudo[0].semanasDUM || this.laudo[0].diasDUM) && i === 0)
        folha.push(
          `Idade gestacional (DUM) ${
            this.laudo[0].semanasDUM ? this.laudo[0].semanasDUM : ''
          }${
            this.laudo[0].semanasDUM
              ? this.laudo[0].semanasDUM > 1
                ? ' semanas'
                : ' semana '
              : ''
          }${this.laudo[0].semanasDUM && this.laudo[0].diasDUM ? ' e ' : ''}${
            this.laudo[0].diasDUM ? this.laudo[0].diasDUM : ''
          }${
            this.laudo[0].diasDUM
              ? this.laudo[0].diasDUM > 1
                ? ' dias'
                : 'dia'
              : ''
          }.`
        );

      if (i == 0) {folha.push(`espaço`);
      folha.push(`espaço`);

      folha.push(`espaço`);
        
        folha.push(`gest`);}

      
      

      if (this.laudo[i].numPlacentas) folha.push(`Corionicidade`);

      if (this.laudo[i].numPlacentas)
        folha.push(
          `Número de Placentas: ${this.laudo[i].numPlacentas}.`
        );

      if (this.laudo[i].numBolsasAmni){
        folha.push(
          `Número de bolsas amnióticas: ${this.laudo[i].numBolsasAmni}.`
        );
        folha.push(`espaço`);
      folha.push(`espaço`);
      }

      

      if (this.laudo.length > 1){ folha.push(`feto${i}`)
      folha.push(`espaço`);
      folha.push(`espaço`);

      folha.push(`espaço`);
      folha.push(`espaço`);
      folha.push(`espaço`);
      }
      if (this.laudo[i].placenta)
        folha.push(`Placenta ${this.laudo[i].placenta}.`);
      if (this.laudo[i].comprimentoCabecaNadegas)
        folha.push(
          `Comprimento cabeça-nádegas: ${this.laudo[i].comprimentoCabecaNadegas} mm.`
        );
      if (this.laudo[i].frequenciaCardiacaFetal)
        folha.push(
          `Frequência cardíaca fetal: ${this.laudo[i].frequenciaCardiacaFetal} bpm.`
        );
      folha.push(`espaço`);
      if (folha.length > 0) folha.splice(0, 0, '');
      this.transdutor = [...folha];
      folha = [];

      if (folha.length > 0) folha.splice(0, 0, 'Corionicidade');
      this.corionicidade = [...folha];
      folha = [];

      if (this.laudo[i].diametroBiparietalPrimeiroTrimestre)
        folha.push(
          `Diâmetro biparietal: ${this.laudo[i].diametroBiparietalPrimeiroTrimestre} mm.`
        );
      if (this.laudo[i].circunferenciaCefalicaPrimeiroTrimestre)
        folha.push(
          `Circunferência cefálica: ${this.laudo[i].circunferenciaCefalicaPrimeiroTrimestre} mm.`
        );
      if (this.laudo[i].circunferenciaAbdominalPrimeiroTrimestre)
        folha.push(
          `Circunferência abdominal: ${this.laudo[i].circunferenciaAbdominalPrimeiroTrimestre} mm.`
        );
      if (this.laudo[i].circunferenciaAbdominalPrimeiroTrimestre)
        folha.push(`Fêmur: ${this.laudo[i].femurPrimeiroTrimestre} mm.`);
      if (this.laudo[i].pesoPrimeiroTrimestre)
        folha.push(`Peso: ${this.laudo[i].pesoPrimeiroTrimestre} g.`);

      if (this.laudo[i].cranioPrimeiroTrimestre)
        folha.push(`Crânio ${this.laudo[i].cranioPrimeiroTrimestre === 'outros' ? this.laudo[i].comentariosAdcionaisCranio : this.laudo[i].cranioPrimeiroTrimestre}.`);       

      if (this.laudo[i].sistemaNervosoCentralPrimeiroTrimistre)
        folha.push(
          `Sistema nervoso central ${this.laudo[i].sistemaNervosoCentralPrimeiroTrimistre === 'outros' ? this.laudo[i].sistemaNervosoCentralPrimeiroTrimistreOutros : this.laudo[i].sistemaNervosoCentralPrimeiroTrimistre}.`
        );
      if (
        this.laudo[i].translucenciaInstracranianaPrimeiroTrimistre &&
        this.laudo[i].translucenciaInstracranianaPrimeiroTrimistre ==
          'presente medindo'
      )
        folha.push(
          ` Translucência intracraniana presente, medindo ${this.laudo[i].translucenciaInstracranianaPrimeiroTrimistreMedindo} mm.`
        );

      if (this.laudo[i].orbitasPrimeiroTrimistre)
        folha.push(`Órbitas ${this.laudo[i].orbitasPrimeiroTrimistre}.`);
      if (this.laudo[i].perfilFetalPrimeiroTrimistre)
        folha.push(
          `Perfil fetal ${this.laudo[i].perfilFetalPrimeiroTrimistre === 'outros' ? this.laudo[i].perfilFetalPrimeiroTrimistreOutros : this.laudo[i].perfilFetalPrimeiroTrimistre}.`
        );
      if (this.laudo[i].estomagoPrimeiroTrimistre)
        folha.push(`Estômago ${this.laudo[i].estomagoPrimeiroTrimistre}.`);
        if (this.laudo[i].coracaoCampo)
        folha.push(`Coração: ${this.laudo[i].coracaoCampo}.`);
      
      if (this.laudo[i].paredeAbdominalPrimeiroTrimistre)
        folha.push(
          `Parede abdominal ${this.laudo[i].paredeAbdominalPrimeiroTrimistre === 'outros' ? this.laudo[i].paredeAbdominalPrimeiroTrimistreOutros : this.laudo[i].paredeAbdominalPrimeiroTrimistre}.`
        );

      if (this.laudo[i].bexigaPrimeiroTrimistre)
        folha.push(`Bexiga ${this.laudo[i].bexigaPrimeiroTrimistre === 'outros' ? this.laudo[i].bexigaPrimeiroTrimistreOutros : this.laudo[i].bexigaPrimeiroTrimistre}.`);

      if (this.laudo[i].cordaoUmbilicalPrimeiroTrimistre)
        folha.push(
          `Cordão umbilical ${this.laudo[i].cordaoUmbilicalPrimeiroTrimistre}${
            this.laudo[i].cordaoUmbilicalInseridoNa
              ? ' ' + this.laudo[i].cordaoUmbilicalInseridoNa
              : ''
          }.`
        );
      if (this.laudo[i].membrosSuperioresPrimeiroTrimistre)
        folha.push(
          `Membros superiores ${this.laudo[i].membrosSuperioresPrimeiroTrimistre}.`
        );
      if (this.laudo[i].membrosInferioresPrimeiroTrimistre)
        folha.push(
          `Membros inferiores ${this.laudo[i].membrosInferioresPrimeiroTrimistre}.`
        );
      if (this.laudo[i].artereiasRenaisPrimeiroTrimistre)
        folha.push(
          `Artérias renais ${this.laudo[i].artereiasRenaisPrimeiroTrimistre}.`
        );
      if (folha.length > 0) folha.splice(0, 0, 'Morfologia fetal');
      this.morfologiaFetal = [...folha];
      folha = [];

      if (this.laudo[i].transulucenciaNucal)
        folha.push(
          `Translucência nucal: ${this.laudo[i].transulucenciaNucal} mm.`
        );
      if (this.laudo[i].ossoNasal)
        folha.push(`Osso nasal ${this.laudo[i].ossoNasal}.`);
      if (this.laudo[i].fluxoTriscupide)
        folha.push(`Fluxo tricúspide ${this.laudo[i].fluxoTriscupide}.`);
      if (this.laudo[i].ductoVenoso)
        folha.push(`Ducto venoso ${this.laudo[i].ductoVenoso}.`);
      if (folha.length > 0)
        folha.splice(0, 0, 'Marcadores para cromossomopatias');
      this.marcadoresParaCromossomopatias = [...folha];
      folha = [];

      if (this.laudo[i].riscoTrissomiaum)
        folha.push(`Risco basal (baseado na idade materna):`);
      if (this.laudo[i].riscoTrissomiaum) folha.push(`TrissomiaUm${i}`);
      //Trissomia do 21 1: ${this.laudo[i].riscoTrissomiaum}.
      if (this.laudo[i].riscoTrissomiadois)
        folha.push(`Risco corrigido (baseado na idade materna, TN e FCP):`);
      if (this.laudo[i].riscoTrissomiadois) folha.push(`TrissomiaDois${i}`);
      if (folha.length > 0) folha.splice(0, 0, 'Risco para trissomias');
      folha.push(`espaço`);
      this.riscoParaTrissomias = [...folha];
      folha = [];

      if (this.laudo[0].arteriaUterinaDireita && i == this.laudo.length - 1)
        folha.push(`Artéria uterina direita:`);
      if (this.laudo[0].arteriaUterinaDireita && i == this.laudo.length - 1)
        folha.push(`IP: ${this.laudo[0].arteriaUterinaDireita}.`);
      if (this.laudo[0].arteriaUterinaEsquerda && i == this.laudo.length - 1)
        folha.push(`Artéria uterina esquerda: `);
      if (this.laudo[0].arteriaUterinaEsquerda && i == this.laudo.length - 1)
        folha.push(`IP: ${this.laudo[0].arteriaUterinaEsquerda}.`);

      if (
        i == this.laudo.length - 1 &&
        this.laudo[0].camposExibidos.exibeMediaIP &&
        !!this.laudo[0].arteriaUterinaEsquerda &&
        !!this.laudo[0].arteriaUterinaDireita
      )
        folha.push(
          `Média do IP: ${
            this.laudo[0].camposExibidos.exibeMediaIP
              ? parseFloat(
                  (parseFloat(this.laudo[0].arteriaUterinaEsquerda) +
                    parseFloat(this.laudo[0].arteriaUterinaDireita)) /
                    2.0
                ).toFixed(2)
              : ''
          }.
         
        ${this.laudo[0].percentil ? 'MoM' : ''}
        ${this.laudo[0].percentil ? this.laudo[0].percentil : ''}
        ${this.laudo[0].percentil ? '.' : ''}`
        
        );
      // !isNaN(this.laudo[i].MediaIp)
      if (this.laudo[0].riscoBasalPreEclampsia && i == this.laudo.length - 1)
        folha.push(`riscoPrec${0}`);
      if (
        this.laudo[0].riscoCorrigidoPreEclampsia &&
        i == this.laudo.length - 1
      )
        folha.push(`riscoCorrigidoPreEclampsia${0}`);
        if (this.laudo[i].riscoRestricaoCrescimento)
        folha.push(
          `Risco de restrição de crescimento fetal antes de 37 semana – 1: ${this.laudo[i].riscoRestricaoCrescimento}.`
        );


      if (folha.length > 0) folha.splice(0, 0, 'Risco para pré-eclâmpsia');
      this.riscoParaPreeclampsia = [...folha];
      folha = [];

      if (
        (this.laudo[0].coloUterino || this.laudo[0].coloMedidaEmRepouco) &&
        i == this.laudo.length - 1
      )
        folha.push(`coloUter${0}`);
      if (this.laudo[0].coloPassouAMedir && i == this.laudo.length - 1)
        folha.push(`coloPassouAMedir${0}`);
      //
      if (this.laudo[0].orificioCervicalInterno && i == this.laudo.length - 1)
        folha.push(
          `Orifício cervical interno ${this.laudo[0].orificioCervicalInterno}.`
        );
      if (
        i == this.laudo.length - 1 &&
        this.laudo[0].polipoEndocervicalMedindoCm &&
        this.laudo[0].polipoEndocervicalMedindo !==
          'Observamos pólipo endocervical medindo'
      )
        folha.push(`${this.laudo[0].polipoEndocervicalMedindo} `);

      if (
        i == this.laudo.length - 1 &&
        this.laudo[0].polipoEndocervicalMedindoCm &&
        this.laudo[0].polipoEndocervicalMedindo ===
          'Observamos pólipo endocervical medindo'
      )
        folha.push(`polipoMede${0}`);
      if (this.laudo[0].protusaoBolsaAmniotica && i == this.laudo.length - 1)
        folha.push(
          `${this.laudo[0].protusaoBolsaAmniotica} de protrusão da bolsa amniótica no momento do exame.`
        );
      if (this.laudo[0].sinalDeSludge && i == this.laudo.length - 1)
        folha.push(`Sinal de sludge ${this.laudo[0].sinalDeSludge}.`);
      if (
        i == this.laudo.length - 1 &&
        this.laudo[0].polipoEndocervicalMedindo &&
        this.laudo[0].polipoEndocervicalMedindo === 'aberto para'
      )
        folha.push(
          `Orifício cervical interno ${this.laudo[0].orificioCervicalInterno} ${this.laudo[0].orificioCervicalInternoDois} mm `
        );
      if (folha.length > 0) {
        folha.push(`espaço`);
        folha.push(`espaço`);
        folha.push(`espaço`);
        folha.push(`espaço`);
      }
      // folha.push(`espaço`);
      // folha.push(`espaço`);
      // folha.push(`espaço`);
      // folha.push(`espaço`);

      if (folha.length > 0) folha.splice(0, 0, 'Perfil cervical');
      this.perfilCervical = [...folha];
      folha = [];

      if (this.laudo.length == 1 && i == this.laudo.length - 1)
        folha.push(`Gestação única com feto vivo.`);
      if (this.laudo.length == 2 && i == this.laudo.length - 1)
        folha.push(`Gestação gemelar com feto vivo.`);
      if (this.laudo.length == 3 && i == this.laudo.length - 1)
        folha.push(`Gestação trigemelar com feto vivo.`);
      if (this.laudo.length == 4 && i == this.laudo.length - 1)
        folha.push(`Gestação quadrigemelar com feto vivo.`);
      if (this.laudo.length == 5 && i == this.laudo.length - 1)
        folha.push(`Gestação quintupla com feto vivo.`);

      if (this.laudo[0].semanasDUM2 && i == this.laudo.length - 1)
        folha.push(`idadeGest${0}`);
      if (!this.laudo[0].incerta && i == this.laudo.length - 1)
        if (!this.laudo[0].incerta && i == this.laudo.length - 1)
          if (this.laudo[0].dataProvavelParto) folha.push(`dataProv${i}`);

      if (this.laudo[0].riscoTrissomia && i == this.laudo.length - 1)
        folha.push(`riscoTrissomia${0}`);

      if (
        this.laudo[0].morfologiafetalIdadeGestacional &&
        this.laudo[0].morfologiafetalIdadeGestacional !=
          'Alterações precoces da morfologia fetal, caracterizada por' &&
        i == this.laudo.length - 1
      )
        folha.push(`${this.laudo[0].morfologiafetalIdadeGestacional}.`);

      if (
        this.laudo[0].morfologiafetalIdadeGestacional &&
        this.laudo[0].morfologiafetalIdadeGestacional ===
          'Alterações precoces da morfologia fetal, caracterizada por' &&
        i == this.laudo.length - 1
      )
        folha.push(
          `${this.laudo[0].morfologiafetalIdadeGestacional} ${this.laudo[0].morfologiafetalIdadeGestacionaUmOutros}`
        );

      if (
        this.laudo[i].triagemPreEclampsia &&
        this.laudo[i].triagemPreEclampsia ===
          'A triagem combinada para pré-eclâmpsia para uma única gestação não é aplicável a gestações múltiplas no momento presente. Dado que a gestação múltipla é considerada um fator de risco para pré-eclâmpsia (especialmente pré- eclâmpsia de inicio tardio - critério epidemiológico maior), a profilaxia com AAS 150mg/24h em todas as gestações múltiplas devem ser iniciados entre 12-16 semanas até a semana 36'
      )
        folha.push(`${this.laudo[i].triagemPreEclampsia}.`);

      if (this.laudo[i].conclusaoTres)
        folha.push(`${this.laudo[i].conclusaoTres}`);
      if (this.laudo[i].coloUterinoConclusao)
        folha.push(`Colo uterino ${this.laudo[i].coloUterinoConclusao === 'outros' ? this.laudo[i].coloUterinoConclusaoOutros : this.laudo[i].coloUterinoConclusao}.`);

      if (
        this.laudo[0].camposExibidos.visibilidadeCriMed == true &&
        i == this.laudo.length - 1
      )
        folha.push(
          'A critério médico, sugerimos pesquisa do cariótipo fetal, ultrassonografia morfológica entre 20 e 24 semanas e ecocardiograma fetal.'
        );

      if (
        this.laudo[i].riscoColoUterino ===
          'A gestante foi classificada como baixo risco para desenvolver pré-eclâmpsia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)'
       
      )
        folha.push(`fraseRisco1`);
      if (
        this.laudo[i].riscoColoUterino ===
          'A gestante foi classificada como alto risco para desenvolver pré-eclâmpsia tardia (risco de pré-eclâmpsia antes de 37 semanas ≥ 1:100)' 
       
      )
        folha.push(`fraseRisco2`);
      if (
        this.laudo[i].riscoColoUterino ===
          'A gestante foi classificada como risco aumentado para desenvolver pré-eclâmpsia precoce (risco de pré-eclâmpsia antes de 34 semanas ≥ 1:100)'
       
      )
        folha.push(`fraseRisco3`);
       
        if (
        this.laudo[0].riscoPartoEsp
       
      )
        folha.push(`Risco de parto espontâneo antes de 34 semanas, baseado na história materna:
        ${this.laudo[0].riscoPartoEsp} % (1 em ${this.laudo[0].riscoPartoEsp2})
        `);

      if (
        this.laudo[0].camposExibidos.exibeFrase2 === true &&
        i == this.laudo.length - 1
      )
        folha.push(`Risco aumentado para restrição de crescimento fetal.`);

      if (
        this.laudo[0].camposExibidos.exibeFrase1 === true &&
        i == this.laudo.length - 1
      )
        folha.push(`De acordo com o Estudo ASPRE, mulheres identificadas
         pela triagem do primeiro trimestre como de alto risco para pré-eclâmpsia,
          devem utilizar aspirina 150mg à noite entre 11 - 14 e 36 semanas de gestação.`);
      if (
        this.laudo[0].comentariosAdcionaisFinalUm &&
        i == this.laudo.length - 1
      )
        folha.push(`${this.laudo[0].comentariosAdcionaisFinalUm}`);
      folha.push(`espaço`);
      folha.push(`espaço`);

      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(`NOTAS`);
        if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )folha.push(
          `A Fetal Medicine Foundation calcula o risco de trissomia em:`
        );
      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(`■ Baixo risco, o risco corrigido < 1:1000`);
      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(
          `■ Risco intermediário, o risco corrigido entre 1:51 - 1:1000`
        );
      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(`■ Risco alto, o risco corrigido ≤ 1:50`);

      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(
          `* Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.`
        );
      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      )
        folha.push(
          `* Risco corrigido intermediário - incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos. Considerar a realização de NIPT.`
        );
      if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        i == this.laudo.length - 1
      ) {
        folha.push(`fraseBug1`);
      }

      if (
        this.laudo[0].camposExibidos.notasFrase === true &&
        i == this.laudo.length - 1
      )
        folha.push(`O risco de pré-eclâmpsia foi avaliado por uma combinação de características maternas e histórico médico com medidas de pressão arterial e fluxo sanguíneo para o útero.`);
        if (this.laudo[i].camposExibidos.notasFrase2 === true )
          folha.push(
            `O risco de trissomias foi avaliado por uma combinação de idade materna, espessura da translucência nucal fetal e frequência cardíaca fetal.`
          );
      
      
        if (
        this.laudo[0].camposExibidos.exibeFetal === true &&
        this.laudo[0].notasFinalUm &&
        i == this.laudo.length - 1
      ) {
        folha.push(`espaço`);
        folha.push(`espaço`);
        folha.push(`espaço`);
      }

      if (this.laudo[0].notasFinalUm && i == this.laudo.length - 1)
        folha.push(`Notas:  ${this.laudo[0].notasFinalUm}`);

      if (folha.length > 0 && i == this.laudo.length - 1)
        folha.splice(0, 0, 'Conclusão');
      this.conclusao = [...folha];
      folha = [];

      todos.push(...this.transdutor);
      todos.push(...this.corionicidade);

      todos.push(...this.morfologiaFetal);
      todos.push(...this.marcadoresParaCromossomopatias);
      var tamanhoTodos2 =
        this.transdutor.length +
        this.corionicidade.length +
        this.morfologiaFetal.length +
        this.marcadoresParaCromossomopatias.length;
      var tamanhoRiscos = this.riscoParaTrissomias.length;
      var somaTudo = tamanhoTodos2 + tamanhoRiscos;
      var diferencaParaRiscos = 41 - tamanhoTodos2;
      
      let ajustaRiscos = [];
      if (somaTudo > 41) {
       
        for (let i = 0; i < diferencaParaRiscos; i++) {
          ajustaRiscos.push('espaço');
      
        }
      }

      todos.push(...this.riscoParaTrissomias);
      
      var tamanhoTodos =
        this.transdutor.length +
        this.corionicidade.length +
        this.morfologiaFetal.length +
        this.marcadoresParaCromossomopatias.length +
        this.riscoParaTrissomias.length;
      var diferencaPrimeiraFolha = 41 - tamanhoTodos;
      let ajustador = [];
      if (
        tamanhoTodos < 41 &&
        this.title.includes('AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE')
      ) {
        for (var x = 0; x < diferencaPrimeiraFolha; x++) {
          todos.push(`espaço`);
          ajustador.push(`espaço`);
        }
      }
      todos.push(...this.riscoParaPreeclampsia);
      if (!!this.laudo[0]?.coloUterino) {
        todos.push(...this.perfilCervical);
      }
      let ajustador1 = [];
      if (todos.length + this.conclusao.length > 41) {
        let diferenca = 41 - todos.length;

        for (let j = 0; j < diferenca; j++) {
          todos.push(`espaço`);
          ajustador1.push(`espaço`);
        }
      }
      todos.push(...this.conclusao);
      arrayDeFetos[i] = [
        ...this.transdutor,
        ...this.corionicidade,
        ...this.morfologiaFetal,
        ...this.marcadoresParaCromossomopatias,
        ...ajustaRiscos,
        ...this.riscoParaTrissomias,
        ...ajustador,
        ...this.riscoParaPreeclampsia,
        ...this.perfilCervical,
        ...ajustador1,
        ...this.conclusao,
      ];

      let ultimosElementos = [];
      let tamanhoFolha = 41;
      while (arrayDeFetos[i].length > 0) {
        this.listaDeFetos.push(arrayDeFetos[i].splice(0, tamanhoFolha));
        tamanhoFolha = 43;
        let listaVez = this.listaDeFetos[this.listaDeFetos.length - 1]
        if(this.listaDeFetos[this.listaDeFetos.length - 1][this.listaDeFetos[this.listaDeFetos.length - 1].length - 1] === 'NOTAS'){
          this.listaDeFetos[this.listaDeFetos.length - 1].pop()
         

          arrayDeFetos[i].unshift("NOTAS")
          
        }
       
      }
   
    }

    // while (arrayDeFetos.length > 0) {
    //   this.listaDeFetos.push(arrayDeFetos.splice(0, 43));
    // }
  },

  data() {
    return {
      transdutor: [],
      corionicidade: [],
      morfologiaFetal: [],
      marcadoresParaCromossomopatias: [],
      riscoParaTrissomias: [],
      riscoParaPreeclampsia: [],
      perfilCervical: [],
      conclusao: [],
      listaDeFetos: [],
      notas: [],
      via: '',
      tamanhoElemento: [],
      titulos: [
      'História materna',
        'Transdutor',
        'Corionicidade',
        'Morfologia fetal',
        'Marcadores para cromossomopatias',
        'Risco para trissomias',
        'Risco para pré-eclâmpsia',
        'Perfil cervical',
        'Conclusão',
        'NOTAS',
        'História obstétrica anterior',
        'Perdas gestacionais'
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    text-align: justify;
    margin-left: 5px;
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }
 
  div {
    text-align: justify;
    margin-left: 0px;
    font-family: 'Calibri';
  }

  h4 {
    text-align: justify;
    margin-left: -5px;
    margin: 0px;
    margin-top: 0px;
    line-height: 5px;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -3px;

    margin-top: 1x;
    padding: 2px;
  }
  br {
    display: block;
    margin-top: 0px;
    margin-left: 0px;
    line-height: 100%;
  }
  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }

  footer {
    //position: absolute;
    bottom: 0;
    margin-left: 0px;

    width: 80%;
    height: 100px;
    text-align: left;
    line-height: 20px;
  }
}
</style>
